import React from 'react';
import './Welcome.css';

const Welcome = () => {
  return (
    <>
    <div className="welcome-container">
      <h1>Welcome to Immigration Advisor</h1>
      <p>Your trusted partner for all your immigration needs</p>
      
    </div>
    
  </>
  );
};

export default Welcome;
