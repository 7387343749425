import React, { useEffect, useState } from "react";
import "./../../css/formField.css";
import "./../../css/payevidence.css";
import { useNavigate } from "react-router-dom";

const FormField = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email_id: "",
    emp_ref: [],
    pay_evi: [],
    others: [],
  });

  const [fileCounts, setFileCounts] = useState({
    emp_ref: 0,
    pay_evi: 0,
    others: 0,
  });

  const onchangeElement = (e) => {
    const { name, files, value } = e.target;
    console.log(files);

    if (name === "email_id") {
      setData({ ...data, [name]: value });
    } else if (files && files.length > 0) {
      const fileList = Array.from(files);
      console.log(fileList);

      const allowedTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];

      const filesValidated = fileList.filter((file) => {
        if (!allowedTypes.includes(file.type)) {
          alert("Please upload only PDF, PNG, or JPEG files.");
          e.target.value = "";
          return false;
        }

        if (name !== "emp_ref" && file.size > 1e6) {
          alert(
            "File size is more than 1MB. Please upload a file with size less than 1MB."
          );
          e.target.value = "";
          return false;
        }

        if (data[name].some((existingFile) => existingFile.name === file.name)) {
          alert("This file has already been uploaded.");
          return false;
        }

        return true;
      });

      if (filesValidated.length > 0) {
        setData((prevData) => ({
          ...prevData,
          [name]: [...prevData[name], ...filesValidated],
        }));
        setFileCounts((prevCounts) => ({
          ...prevCounts,
          [name]: prevCounts[name] + filesValidated.length,
        }));
      }
    }
  };

  useEffect(() => {
    console.log(data);
  }, [data]);

  const checkMandatoryFields = () => {
    const mandatoryFields = ["email_id", "emp_ref", "pay_evi"];
    for (let field of mandatoryFields) {
      if (
        !data[field] ||
        (Array.isArray(data[field]) && data[field].length === 0)
      ) {
        alert(`Please fill all mandatory fields.`);
        return false;
      }
    }
    return true;
  };

  const upload = async () => {
    if (!checkMandatoryFields()) {
      return;
    }
    const formData = new FormData();
    formData.append("email_id", data.email_id);
    data.emp_ref.forEach((file) => formData.append("emp_ref", file));
    data.pay_evi.forEach((file) => formData.append("pay_evi", file));
    data.others.forEach((file) => formData.append("others", file));

    const settings = {
      method: "POST",
      body: formData,
    };

    try {
      const fetchResponse = await fetch(
        "https://api.xhorizons-uat.lexxtechnologies.com/uploademp",
        settings
      );
      const responseJson = await fetchResponse.json();
      if (fetchResponse.ok) {
        if (responseJson.error) {
          alert(responseJson.error); // Error from Python backend
        } else {
          navigate("/formsubmitted");
        }
      } else {
        alert(`Upload failed. HTTP Status: ${fetchResponse.status}`);
      }
    } catch (e) {
      console.log(e);
      alert(
        "An error occurred during upload. Please check the console for details."
      );
    }
  };

  return (
    <div className="form_body">
      <div className="h-context">
        <h3 className="pt-5 form_heading">Upload Documents</h3>
        <ul>
          <li style={{ color: "red" }}>
            The documents should be clear and colored.
          </li>
          <li style={{ color: "red" }}>
            Please note that fields marked with an asterisk (*) are mandatory.
          </li>
          <li style={{ color: "red" }}>
            Please upload files in PDF, PNG, or JPEG format only.
          </li>
          <li style={{ color: "red" }}>
            Please upload two Pay Evidence for each employment.
          </li>
        </ul>
      </div>
      <form id="form1" className="pb-10" encType="multipart/form-data">
        <div>
          <h3 className="heading-form personal_details mb-5">
            EMPLOYMENT DETAILS
          </h3>
          <div className="maincontainer">
            <div className="evidences">
              <label>
                Email{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input type="text" name="email_id" onChange={onchangeElement} />
            </div>
            <div className="evidences">
              <label>
                Employment Reference{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                accept=".pdf, image/*"
                type="file"
                name="emp_ref"
                multiple
                onChange={onchangeElement}
              />
              <div>Selected files: {fileCounts.emp_ref}</div>
              <ul>
                {data.emp_ref.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
            <div className="evidences">
              <label>
                Pay Evidence{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                accept=".pdf, image/*"
                type="file"
                name="pay_evi"
                multiple
                onChange={onchangeElement}
              />
              <div>Selected files: {fileCounts.pay_evi}</div>
              <ul>
                {data.pay_evi.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="evidences">
            <label> Additional Documents </label>
            <input
              multiple
              onChange={onchangeElement}
              accept=".pdf, image/*"
              type="file"
              name="others"
            />
            <div>Selected files: {fileCounts.others}</div>
            <ul>
              {data.others.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </form>
      <div>
        <button onClick={upload} className="payevidencesubmit" type="button">
          Submit
        </button>
      </div>
    </div>
  );
};

export default FormField;
