// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maincontainer{
    display: flex;
    gap: 10px;
}


.evidences{
    display: flex;
  flex-direction: column;
  width: 87%;
  position: relative;
}
.evidences ul{
    max-width: 100px !important;
}

.evidences label {
    display: flex;
    font-size: small;
    font-weight: 500;
    margin-bottom: 5px;
    letter-spacing: 1px;
    position: relative;
}

.evidences input{
    display: flex;
    border-radius: 5px;
    border: 2px solid #0971ce;
    padding: 5px;
    height: 28px;
    font-size: small;
    letter-spacing: 1px;
    position: relative;
}

.evidences select{
    display: flex;
    border-radius: 5px;
    border: 2px solid #0971ce;
    padding: 5px;
    height: auto;
    background-color: white;
    font-size: small;
    letter-spacing: 1px;
    position: relative;
}
.payevidencesubmit {
    display: flex;
    margin: auto;
    width: 150px;
    background-color: #0971ce;
    border-radius: 50px;
    padding: 15px;
    font-size: medium;
    padding-left: 45px;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;

  }`, "",{"version":3,"sources":["webpack://./src/css/payevidence.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;;AAGA;IACI,aAAa;EACf,sBAAsB;EACtB,UAAU;EACV,kBAAkB;AACpB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;;EAEjB","sourcesContent":[".maincontainer{\n    display: flex;\n    gap: 10px;\n}\n\n\n.evidences{\n    display: flex;\n  flex-direction: column;\n  width: 87%;\n  position: relative;\n}\n.evidences ul{\n    max-width: 100px !important;\n}\n\n.evidences label {\n    display: flex;\n    font-size: small;\n    font-weight: 500;\n    margin-bottom: 5px;\n    letter-spacing: 1px;\n    position: relative;\n}\n\n.evidences input{\n    display: flex;\n    border-radius: 5px;\n    border: 2px solid #0971ce;\n    padding: 5px;\n    height: 28px;\n    font-size: small;\n    letter-spacing: 1px;\n    position: relative;\n}\n\n.evidences select{\n    display: flex;\n    border-radius: 5px;\n    border: 2px solid #0971ce;\n    padding: 5px;\n    height: auto;\n    background-color: white;\n    font-size: small;\n    letter-spacing: 1px;\n    position: relative;\n}\n.payevidencesubmit {\n    display: flex;\n    margin: auto;\n    width: 150px;\n    background-color: #0971ce;\n    border-radius: 50px;\n    padding: 15px;\n    font-size: medium;\n    padding-left: 45px;\n    color: white;\n    font-weight: bold;\n    letter-spacing: 1px;\n    cursor: pointer;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
