/*******************************************************************************
* Email sent :After the createlist when click on submit then show the email sent 
  pop-up 
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React from "react";
//simple popup component after send to mail
const Emailsent = (props) => {
  return (
    <div className="emailsentpopup">
      <div className="emailsentpop">
        <div className="emailpopmessage">
          <h1>
            
            <h1 className="h1-text">Thank You !</h1>
           <p>Your documents has been uploaded successfully.</p> 
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Emailsent;
