// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-container {
    text-align: center;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin: 20px;
  }
  
  .welcome-container h1 {
    color: #333;
  }
  
  .welcome-container p {
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/WelcomePopup/Welcome.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb","sourcesContent":[".welcome-container {\n    text-align: center;\n    padding: 20px;\n    background-color: #f4f4f4;\n    border-radius: 5px;\n    margin: 20px;\n  }\n  \n  .welcome-container h1 {\n    color: #333;\n  }\n  \n  .welcome-container p {\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
