/*******************************************************************************
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React from "react";
import Header from "./components/LexxHeader/Header";
import { Routes, Route } from "react-router-dom";
import { StatusProvider } from "./statusContext";
import FormFiled from "./components/Form/FormField";
import PopUp from "./components/PopUp/PopUp";
import Welcome from "./components/WelcomePopup/Welcome";
import Pas from "./components/Form/Pas";
import Skill from "./components/Form/skill";
import TG from "./components/Form/Tg";
import PayEvidence from "./components/Form/PayEvidence"

function App() {
  //for keycloack login
  return (
    <StatusProvider>
      <div>
        <Header />
        <Routes>
          {/* submit the result after upload */}
          <Route exact path="/" element={<Welcome />}></Route>
          <Route exact path="/rpl" element={<FormFiled />}></Route>
          <Route exact path="/pas" element={<Pas />}></Route>
          <Route exact path="/skills" element={<Skill />}></Route>
          <Route exact path="/tg" element={<TG />}></Route>
          <Route exact path="/formsubmitted" element={<PopUp/>}></Route>
          <Route exact path="/emp" element={<PayEvidence/>}></Route>
        </Routes>
      </div>
    </StatusProvider>
  );
}

export default App;
